﻿$column-gap: rem(24px);

@import '~bulma/sass/grid/columns';
.columns {
	display: block;

	@include tablet {
		display: flex;
	}

	&.is-centered-till-tablet {
		display: flex;
		justify-content: center;

		@include tablet {
			justify-content: flex-start;
		}
	}
}

.column {
	width: 100%;

	&.is-pulled-left {
		order: -1;
	}

	&.has-min-height {

		@include desktop {
			min-height: rem(546px);
		}
	}

	&.has-content-valign {
		display: flex;
		align-items: center;
	}

	&.has-content-align {
		display: flex;
		justify-content: center;
	}

	&.has-background-primary {
		background: $primary;
	}

	&.is-12-till-mobile {
		width: 100%;
		max-width: rem(270px);
		flex-basis: initial;
	}
}