﻿$notification-radius: 0;
$notification-padding: rem(34px) rem(40px) rem(20px) rem(40px);

@import '~bulma/sass/elements/notification';

.notification {
	display: flex;
	font-weight: bold;
	width: 100%;
	flex-wrap: wrap;
	background: mix($primary, #fff, 80%);
	color: #fff;
	padding: rem(18px) rem(20px) rem(20px);
}