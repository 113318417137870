﻿$card-shadow: 0;

/*$card-color: $text !default
$card-background-color: $white !default


$card-header-color: $text-strong !default
$card-header-shadow: 0 1px 2px rgba($black, 0.1) !default
	
$card-content-background-color: transparent !default

$card-footer-background-color: transparent !default
$card-footer-border-top: 1px solid $border !default
$card-footer-padding: 0.75rem !default*/
/*$card-header-background-color: #fff;
$card-header-color: $primary;
$card-header-weight: bold;
$card-header-padding: 0 rem(40px);*/

$card-content-padding: 0 rem(40px);

@import '~bulma/sass/components/card';

.card {

	a {
		display: block;
	}

	figure {
		position: relative;
		z-index: 0;
	}
}

.card-content {
	position: relative;
	padding: rem(34px) rem(40px) rem(41px);
	z-index: 1;

	.button {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
	}

	.section:nth-of-type(odd) & {
		background: #f2f2f2;
	}
}
