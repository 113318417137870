﻿.call-to-action {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 0;
	height: rem(400px);
	background: $primary-gradient;

	@include mobile {
		height: rem(500px);
	}

	@include tablet {
		height: rem(600px);
	}

	h2 {
		font-size: rem(40px);
		line-height: rem(40px);
		font-weight: bold;
		color: #fff;
		text-shadow: 0px 5px 11px rgba(0, 0, 0, 0.8);
		margin: 0 0 rem(32px);

		@include desktop {
			font-size: rem(56px);
			line-height: rem(56px);
		}
	}

	&:after {
		position: absolute;
		content: '';
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: url(../../images/pattern.svg) top left;
		background-size: rem(800px) rem(800px);
	}
}

.call-to-action-content {
	padding: rem(67px) 0 rem(80px) 0;
	max-width: rem(672px);
}

.call-to-action-text {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 0;
	height: rem(400px);

	@include mobile {
		height: rem(500px);
	}

	@include tablet {
		height: rem(600px);
	}

	h2 {
		font-size: rem(40px);
		line-height: rem(40px);
		font-weight: bold;
		margin: 0 0 rem(32px);
		color: #fff;
		text-shadow: 0px 5px 11px rgba(0, 0, 0, 0.8);

		@include desktop {
			font-size: rem(56px);
			line-height: rem(56px);
		}
	}

	img {
		position: absolute;
		z-index: -1;
		width: auto;
		max-width: initial;
		height: 100%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}