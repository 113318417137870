﻿.logo {
	position: absolute;
	z-index: 101;
	display: block;
	width: rem(176px);
	height: rem(170px);
	top: rem(40px);
	left: rem(20px);
	background: url(../../images/logo-fresh-source-group.svg) no-repeat center;
	background-size: contain;

	@include tablet {
		left: rem(34px);
	}
}
