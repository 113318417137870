﻿
.navbar.desktop {
	position: fixed;
	z-index: 100;
	max-width: 60%;
	top: rem(40px);
	right: rem(48px);
	opacity: 1;
	transition: all 0.25s ease-in-out;
	visibility: hidden;
	opacity: 0;

	@include desktop-and-hover {
		visibility: visible;
		position: absolute;
		opacity: 1;
	}

	.navbar-menu {
		display: flex;
		flex-wrap: wrap;
		padding: 0 rem(34px);
		border-radius: $radius;
		background: #fff;

		.home & {
			background: rgba(#000, 0.4);
		}
	}

	.navbar-link {
		display: block;
		font-size: rem(18px);
		padding: rem(13px) rem(15px);

		.home & {
			color: #fff;
		}

		&.is-active,
		&:hover {

			.navbar-item-text:after {
				transform: scaleX(1);
			}
		}
	}

	.navbar-item-text {
		position: relative;

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 1px;
			bottom: 0;
			left: 0;
			background: $primary;
			transform: scaleX(0);
			transform-origin: left;
			transition: all 0.25s ease-in-out;

			.home & {
				background: #fff;
			}
		}
	}

	.is-language {

		> .navbar-link {
			position: relative;
			text-indent: -99999px;
			overflow: hidden;
			width: rem(72px);

			&:before {
				@include icon($icon-arrow-bottom);
				position: absolute;
				top: rem(25px);
				right: rem(15px);
				text-indent: 0;
				font-size: rem(10px);
			}

			&:after {
				@include icon($icon-globe);
				position: absolute;
				top: rem(17px);
				left: rem(15px);
				font-size: rem(22px);
				text-indent: 0;
			}
		}

		&:hover {

			.navbar-dropdown {
				display: block;
			}
		}

		.navbar-dropdown {
			position: absolute;
			top: 100%;
			right: rem(40px);
			background: #fff;

			.home & {
				background: rgba(#000, 0.4);
			}

			.navbar-link {
				padding: rem(5px) rem(15px);
			}

			.navbar-item {

				&:first-child .navbar-link {
					padding-top: rem(13px);
				}

				&:last-child .navbar-link {
					padding-bottom: rem(13px);
				}
			}
		}
	}

	.headroom--not-top & {
		right: -100%;
		opacity: 0;
	}
}

.navbar.responsive {
	position: fixed;
	z-index: 1000;
	width: rem(400px);
	height: 100vh;
	top: 0;
	//right: 0;
	right: rem(-400px);
	background: #fff;
	opacity: 0;
	transition: all 0.25s ease-in-out;

	&.is-open {
		right: 0;
		opacity: 1;
	}

	.navbar-menu {
		padding: rem(138px) 0 rem(80px);
	}

	.navbar-item {
		padding: 0;

		&:first-child .navbar-link {
			border-top: 1px solid mix($primary, #fff, 30%);
		}
	}

	.navbar-link {
		display: block;
		width: 100%;
		padding: rem(13px) rem(40px) rem(15px);
		border-bottom: 1px solid mix($primary, #fff, 30%);
		font-size: rem(18px);
		transition: all 0.25s ease-in-out;

		&.is-active {
			color: #fff;
		}

		&:hover {
			color: $primary;
		}

		&:hover,
		&.is-active {
			background: mix($primary, #fff, 30%);
		}
	}

	.is-language {
		position: relative;
		padding: 0 rem(40px);
		margin: rem(40px) 0 0;

		> .navbar-link {
			position: relative;
			border: 1px solid mix($primary, #fff, 30%);
			border-radius: $radius;
			padding: rem(12px) rem(50px) rem(10px) rem(60px);

			&:before {
				@include icon($icon-arrow-bottom);
				position: absolute;
				top: rem(25px);
				right: rem(30px);
				text-indent: 0;
				font-size: rem(10px);
			}

			&:after {
				@include icon($icon-globe);
				position: absolute;
				top: rem(16px);
				left: rem(20px);
				font-size: rem(22px);
				text-indent: 0;
			}
		}

		&.is-open,
		&:hover {

			> .navbar-link {
				border-radius: $radius $radius 0 0;
				background: #fff;
			}

			.navbar-dropdown {
				opacity: 1;
				visibility: visible;
			}
		}

		.navbar-dropdown {
			position: absolute;
			width: calc(100% - 5rem); // 80px
			display: block;
			opacity: 0;
			visibility: hidden;
			transition: all 0.25s ease-in-out;

			.navbar-link {
				padding: rem(5px) rem(15px);
				border: 1px solid mix($primary, #fff, 30%);
				border-top: none;
			}

			.navbar-item {

				&:first-child .navbar-link {
					padding-top: rem(13px);
				}

				&:last-child .navbar-link {
					padding-bottom: rem(13px);
				}
			}
		}
	}
}

.navbar-dropdown {
	display: none;
}

.navbar-background {
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba($primary-contrast, 0.8);
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s ease-in-out;

	&.is-active {
		opacity: 1;
		visibility: visible;
	}
}