﻿@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Regular.woff2') format('woff2'), url('../../fonts/Montserrat-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Medium.woff2') format('woff2'), url('../../fonts/Montserrat-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Bold.woff2') format('woff2'), url('../../fonts/Montserrat-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}


/*@mixin font-title-24 {
	font-size: rem(24px);
	line-height: rem(24px);
}*/

/*@mixin font-text {
	font-family: $base-font-family;
	font-size: rem(22px);
	line-height: rem(30px);

	@media only screen and (min-width: 432px) {
		font-size: rem(21px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(20px);
	}
}



@mixin font-16 {
	font-size: rem(16px);
	line-height: rem(30px);
}

@mixin font-18 {
	font-size: rem(18px);
	line-height: rem(20px);
}

@mixin font-20 {
	font-size: rem(20px);
	line-height: rem(38px);
}

@mixin font-24 {
	font-size: rem(24px);
	line-height: rem(30px);
}

@mixin font-30 {
	font-size: rem(30px);
	line-height: rem(30px);
}

@mixin font-30 {
	font-size: rem(30px);
	line-height: rem(30px);

	@include bp-min(s) {
		font-size: rem(36px);
		line-height: rem(36px);
	}
}

@mixin font-48 {
	font-size: rem(38px);
	line-height: rem(40px);

	@include bp-min(s) {
		font-size: rem(48px);
		line-height: rem(50px);
	}
}

@mixin font-60 {
	font-size: rem(40px);
	line-height: rem(46px);

	@include bp-min(s) {
		font-size: rem(50px);
		line-height: rem(56px);
	}

	@include bp-min(m) {
		font-size: rem(60px);
		line-height: rem(66px);
	}
}

@mixin font-alt-32 {
	font-family: $base-font-alt;
	font-size: rem(32px);
	line-height: rem(48px);
}

@mixin font-alt-36 {
	font-family: $base-font-alt;
	font-size: rem(35px);
	line-height: rem(50px);
}

@mixin font-alt-68 {
	font-family: $base-font-alt;
	font-size: rem(58x);
	line-height: rem(40px);

	@include bp-min(s) {
		font-size: rem(58px);
		line-height: rem(50px);
	}

	@include bp-min(m) {
		font-size: rem(68px);
		line-height: rem(50px);
	}
}

@mixin font-alt-90 {
	font-family: $base-font-alt;
	font-size: rem(70x);
	line-height: rem(77px);

	@include bp-min(s) {
		font-size: rem(80px);
		line-height: rem(88px);
	}

	@include bp-min(m) {
		font-size: rem(90px);
		line-height: rem(99px);
	}
}*/