﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.2s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	opacity: 1;
	visibility: visible;
	z-index: 1001;
	position: fixed;
	height: 56px;
	width: 100%;
	padding: 0 10px;
	border: 1px solid rgba(#000, 0.1);
	left: 0;
	bottom: 0;
	cursor: pointer;
	background: $primary-gradient;
	color: #fff;
	opacity: 1;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
	font-weight: 400;

	@include desktop {
		height: 56px;
		width: 56px;
		border-radius: 50%;
		margin: 0;
		top: 40px;
		right: 40px;
		left: auto;

		&.is-active {
			right: 56px;
		}
	}

	@include desktop-and-hover {
		opacity: 0;
		visibility: hidden;
	}

	&.is-active {
		//background: $primary;
		//color: $secondary;
		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			//background: $secondary;
		}
	}

	.hamburger-box {
		top: 4px;
	}

	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		transition: all 0.3s ease-in-out;
	}

	&.hamburger--spin.is-active .hamburger-inner::after {
		transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s ease-in-out;
	}

	&:hover {
		//background: $primary;
		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			//background: $secondary;
		}
	}
}