﻿.route-form {
	margin: 0 0 rem(13px);

	@include widescreen {
		display: flex;
	}

	.control {
		margin: 0 0 rem(20px);
	}

	.control.is-text-input {
		flex-grow: 1;
		margin-right: rem(20px);
	}
}
