﻿$table-color: $text;
$table-background-color: transparent;

$table-cell-border: none;
$table-cell-border-width: 0;
$table-cell-padding: 0 0 0;
$table-cell-heading-color: $primary;

//$table-head-cell-border-width: 0 0 2px !default
$table-head-cell-color: $primary;
//$table-foot-cell-border-width: 2px 0 0 !default
//$table-foot-cell-color: $text-strong !default

//$table-head-background-color: transparent !default
//$table-body-background-color: transparent !default
//$table-foot-background-color: transparent !default

//$table-row-hover-background-color: $scheme-main-bis !default

//$table-row-active-background-color: $primary !default
//$table-row-active-color: $primary-invert !default

//$table-striped-row-even-background-color: $scheme-main-bis !default
//$table-striped-row-even-hover-background-color: $scheme-main-ter !default*/

@import '~bulma/sass/elements/table';

.table.shows-contact-information {

	td,
	th {
		border: 0;
		padding: 0;
		font-size: rem(16px);
		line-height: rem(32px);

		a[href^="mailto"] {
			color: #000 !important;
		}

		&:first-child {
			width: rem(110px);
		}
	}
}