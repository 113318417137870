﻿// Colors
$grey: #666;
$grey-light: #f2f2f2;
$grey-dark: #333;
$white: #fff;

// Typography
$family-sans-serif: 'Montserrat', sans-serif;
$body-line-height: 2;

$size-1: rem(40px);
$size-2: rem(32px);
$size-3: rem(32px);
$size-4: rem(24px);
$size-5: rem(18px);
$size-6: rem(16px);


// Responsiveness
$gap: 24px;

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
$fullhd: 1510px + (2 * $gap);

// Miscellaneous
//$radius-small: 2px !default
$radius: 28px;
//$radius-large: 25px;

// Flags
$variable-columns: false;

@import '~bulma/sass/utilities/initial-variables';