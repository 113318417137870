@import '~bulma/sass/components/level';

.level {
	background: #e9e9e9;

	&.is-legal-bar {
		color: #000;
		font-size: rem(12px);
		padding: rem(15px) 0 rem(80px);

		@include desktop {
			padding: rem(15px) 0 rem(14px);
		}

		a {
			color: #000;

			&:hover {
				text-decoration: underline;
			}
		}

		.level-item {
			margin-right: 1.5625rem;
			justify-content: flex-start;
		}
	}
}