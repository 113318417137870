$footer-background-color: #fff;
$footer-color: inherit;
$footer-padding: 0;

@import '~bulma/sass/layout/footer';

.footer {

	.column {
		min-height: rem(240px);
	}
}

.footer-contact-block {
	position: relative;
	padding: rem(69px) rem(20px) rem(40px) rem(175px);
	font-size: rem(18px);
	line-height: rem(32px);

	.title {
		margin: 0 0 rem(3px);
	}

	a {
		color: #000;
	}

	&:before {
		position: absolute;
		content: '';
		width: rem(125px);
		height: rem(120px);
		top: rem(40px);
		left: 0;
		background: url(../../../images/logo-fresh-source-group.svg) no-repeat;
	}
}

.footer-buttons-container {
	padding: rem(40px) 0;
}