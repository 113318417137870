﻿.visual {
	position: relative;
}

.visual-title {
	width: 100%;
	padding: rem(27px) 0 0;
	text-align: center;
	background: url(../../images/visual-top.jpg) top center;
	background-size: contain;

	@include desktop {
		position: absolute;
		z-index: 1;
		top: rem(45px);
		left: 0;
		background: transparent;
	}

	.title {
		display: inline-block;
	}
}

.visual-image {
	@include responsive-container-psudo(768, 432);
	background: url(../../images/visual/visual-768.png) no-repeat;
	background-size: contain;
	z-index: 0;

	@include tablet {
		@include responsive-container-psudo(1024, 576);
		background-image: url(../../images/visual/visual-1024.png);
	}

	@include desktop {
		@include responsive-container-psudo(1216, 684);
		background-image: url(../../images/visual/visual-1216.png);
	}

	@include widescreen {
		@include responsive-container-psudo(1408, 792);
		background-image: url(../../images/visual/visual-1408.png);
	}

	@include fullhd {
		@include responsive-container-psudo(1920, 1080);
		background-image: url(../../images/visual/visual-1920.png);
	}
}

.visual-footer {
	background: #9dd90e;
	width: 100%;
	padding: 0 0 rem(19px);

	@include widescreen {
		position: absolute;
		z-index: 1;
		bottom: rem(40px);
		left: 0;
		background: transparent;
	}
}