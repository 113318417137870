.hero {
	position: relative;
	z-index: 0;
	min-height: rem(250px);

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: url(../../../images/pattern.svg);
		background-size: rem(800px) rem(800px);
		opacity: 0.5;
	}

	.image img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&.is-fullheight .hero-body {
		align-items: flex-start;
	}
}
