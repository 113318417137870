$section-padding: 0;
$section-padding-medium: 0;
$section-padding-large: 0;

$section-background: #e8eaf1;

@import '~bulma/sass/layout/section';

.section {
	position: relative;
	overflow: hidden;
	z-index: 0;
	padding-top: rem(80px);
	padding-bottom: rem(80px);
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	flex-direction: column;

	&:nth-of-type(even) {
		background: #f5f5f5;
	}

	&.has-background {
		position: relative;
		z-index: 0;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: url(../../../images/pattern.svg);
			background-size: rem(800px) rem(800px);
			opacity: 0.5;
		}
	}

	.title {
		margin-top: rem(-7px);
	}
}

.section-home-text-container {
	position: relative;
	z-index: 0;
	background: rgba(#ffffff, 0.8);
	padding: rem(42px) rem(50px) rem(32px);
}

.section-logo {
	position: relative;
	width: 100%;
	height: rem(420px);
	padding: rem(50px) 0;

	&:after {
		position: absolute;
		content: '';
		width: rem(331px);
		height: rem(320px);
		top: rem(50px);
		left: 50%;
		transform: translateX(-50%);
		background: url(../../../images/logo-fresh-source-group.svg) no-repeat;
		background-size: rem(331px) rem(320px);
	}
}