﻿$button-color: #fff;
$button-background-color: $primary-contrast;

$button-border-color: $primary-contrast;
$button-border-width: 0;

//$button-padding-vertical: $control-padding-vertical;
//$button-padding-horizontal: $control-padding-horizontal;

$button-hover-color: mix($primary, $white, 20%);
$button-hover-border-color: mix($primary, $white, 20%);

$button-focus-color: $button-hover-color;
$button-focus-border-color: $button-hover-border-color;

$button-active-color: $button-focus-color;
$button-active-border-color: $button-hover-border-color;

@import '~bulma/sass/elements/button';

.button {
	position: relative;
	z-index: 0;
	height: auto;
	padding: rem(15px) rem(48px) rem(14px);
	border: 0;
	border-radius: $radius;
	font-size: rem(18px);
	font-weight: normal;
	transition: all $speed ease-in-out;
	overflow: hidden;
	text-align: left;
	background: $primary-gradient;
	transition: all 0.25s ease-in-out;
	white-space: initial;

	@include mobile-l {
		white-space: nowrap;
	}

	&:after {
		@include icon($icon-arrow-right);
		position: absolute;
		top: rem(24px);
		right: rem(40px);
		font-size: rem(10px);
		transition: right 0.25s ease-in-out, opacity 0.25s ease-in-out;
		opacity: 0;
	}

	&:hover {
		color: #fff;
		padding: rem(15px) rem(53px) rem(14px) rem(43px);

		&:after {
			right: rem(35px);
			opacity: 1;
		}
	}

	&.is-white {
		background: #fff;
		color: $primary;

		&:hover {
			color: $primary;
		}
	}

	&.is-flat {
		background: none;
		padding: rem(15px) rem(25px) rem(14px) rem(0);
		color: $primary;

		&:after {
			right: rem(5px);
			opacity: 1;
		}

		&:hover {
			background: none;

			&:after {
				right: rem(2px);
			}
		}

		&.is-white {
			color: #fff;
		}
	}
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
	margin-right: rem(40px);
}

.buttons {

	&.is-image-button {
		margin-top: rem(40px);
		justify-content: center;

		.button {
			margin: 0;
		}
	}

	&.is-visual-buttons {
		flex-direction: column;
	}
}