﻿$title-color: $text;
$title-weight: 700;

@import '~bulma/sass/elements/title';

.title {
	position: relative;
	white-space: normal;

	&.is-1 {
		margin: 0 0 rem(-8px);
		color: $primary;
		display: inline-block;
	}

	&.is-2 {
		margin: 0 0 rem(20px);
		color: $primary;
	}

	&.has-prefix {
		position: relative;

		&:before {
			position: relative;
			display: inline-block;
			content: '';
			width: rem(35px);
			height: rem(33px);
			top: 0;
			right: rem(10px);
			background: url(../../../images/prefix.svg);
			background-size: rem(35px) rem(33px);
		}
	}

	&.has-text-centered {
		width: 100%;
	}
}

.title-container {
	text-align: center;
}
